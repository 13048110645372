import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'

class ArchivePage extends Component {

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  renderItem = (el, i) => {
    return (
      <Link to={`/${el.node.slug}`} className='archive__item' key={i}>
        <picture>
          { this.renderImage(el.node.acf.feature_image) }
        </picture>
        <h3>{ el.node.title }</h3>
        <p>Read Article</p>
      </Link>
    )
  }

  render() {
    let { posts } = this.props.data

    return (
      <>
        <SEO title='Insights' bodyClass='archive' />
        <div className='archive__heading'>
          <h1>Insights</h1>
        </div>
        <Fade bottom distance='40px' cascade>
          <div className='archive__list'>
            { posts.edges.map(this.renderItem) }
          </div>
        </Fade>
      </>
    )
  }
}

export default ArchivePage

export const query = graphql`
  query {
    posts: allWordpressPost {
      edges {
        node {
          id
          slug
          title
          acf {
            feature_image {
              ...original
            }
          }
        }
      }
    }
  }
`
